<template>
  <v-container class="px-6 d-block" fluid>
    <div class="d-flex align-center mb-6">
      <v-icon size="15" color="#4ab762">mdi-home</v-icon>
      <v-breadcrumbs :items="breadcrumbs" class="pa-0 ml-2">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
    </div>

    <v-row>
      <v-col cols="12">
        <div class="d-flex align-center">
          <div class="subtitle-1">
            Selamat datang
            <span class="subtitle-1 font-weight-bold text-capitalize">{{
              $store.state.user.name
            }}</span
            >,
            <span v-show="$store.state.user.last_login_datetime"
              >Anda terakhir login
              <span class="subtitle-1 font-weight-bold">
                {{ $store.state.user.last_login_datetime | datetimeFull }}</span
              ></span
            >
          </div>
          <div class="ml-auto">
            <v-btn
              color="#FFF"
              elevation="0"
              width="400"
              height="45"
              @click="dialog.sort_filter = true;"
              class="text-capitalize"
              style="border-radius: 6px 0px 0px 6px !important;">
              <div class="d-flex align-center" style="width: 100%;">
                <div>
                  <!-- Periode Data Berdasarkan -->
                  <span v-if="filter.sort_filter === 'date'">
                    {{ filter.tanggal_mulai | dayMounthYear }} - {{ filter.tanggal_selesai | dayMounthYear }}
                  </span>
                  <span v-if="filter.sort_filter !== 'date'">
                    {{ filter.sort_filter }}
                  </span>
                </div>
                <div class="ml-auto">
                  <v-icon>mdi-chevron-down</v-icon>
                </div>
              </div>
            </v-btn>
            <v-btn
              color="#3498DB"
              elevation="0"
              height="45"
              @click="dialog.filter = true; this.validationReset();"
              style="border-radius: 0px 6px 6px 0px !important;"
              class="text-capitalize white--text">
              <v-icon class="mr-4">mdi-calendar</v-icon>
              Filter Data
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
      <v-row v-if="loading">
        <v-col 
          cols="12" 
          md="3"
          v-for="n in 5"
          :key="n">
          <v-skeleton-loader
            type="image">
          </v-skeleton-loader>
        </v-col>
        <v-col 
          cols="12" 
          md="9">
          <v-skeleton-loader
            type="image">
          </v-skeleton-loader>
        </v-col>
        <!-- <v-col cols="6">
          <v-skeleton-loader
            type="image">
          </v-skeleton-loader>
          <v-skeleton-loader
            type="image">
          </v-skeleton-loader>
        </v-col>
        <v-col cols="6">
          <v-skeleton-loader
            type="image">
          </v-skeleton-loader>
          <v-skeleton-loader
            type="image">
          </v-skeleton-loader>
        </v-col> -->
      </v-row>

      <!-- <div v-if="!loading">
        <v-row class="mb-5">
          <v-col cols="12" md="3">
            <v-skeleton-loader
              class="ma-auto"
              :loading="loading"
              transition="scale-transition"
              :tile="true"
              type="image">
              <v-card
                class="box-shadow border-radius pa-1"
                style="border-top: 5px solid #E67E22">
                <v-card-text>
                  <div style="font-weight: normal; font-size: 14px; color: #505050; line-height: 16px;">
                    Total Kelas
                  </div>
                  <div style="font-weight: 900; font-size: 24px; color: #505050; line-height: 28px; margin-top: 5px;">
                    {{ dashboard.count_course }}
                  </div>
                </v-card-text>
              </v-card>
            </v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="3">
            <v-skeleton-loader
              class="ma-auto"
              :loading="loading"
              transition="scale-transition"
              :tile="true"
              type="image">
              <v-card
                class="box-shadow border-radius pa-1"
                style="border-top: 5px solid #F1C40F">
                <v-card-text>
                  <div style="font-weight: normal; font-size: 14px; color: #505050; line-height: 16px;">
                    Total Kelas Terjual
                  </div>
                  <div style="font-weight: 900; font-size: 24px; color: #505050; line-height: 28px; margin-top: 5px;">
                    {{ dashboard.count_transaction_course_paid }} 
                  </div>
                </v-card-text>
              </v-card>
            </v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="3">
            <v-skeleton-loader
              class="ma-auto"
              :loading="loading"
              transition="scale-transition"
              :tile="true"
              type="image">
              <v-card
                class="box-shadow border-radius pa-1"
                style="border-top: 5px solid #1ABC9C">
                <v-card-text>
                  <div style="font-weight: normal; font-size: 14px; color: #505050; line-height: 16px;">
                    Total Organisasi
                  </div>
                  <div style="font-weight: 900; font-size: 24px; color: #505050; line-height: 28px; margin-top: 5px;">
                    {{ dashboard.count_organization }}
                  </div>
                  <div style="font-size: 12px; line-height: 14px; color: #505050; mix-blend-mode: normal;" class="mt-3 d-flex align-center">
                    <div style="opacity: 0.5;">
                      vs Tahun Lalu
                    </div>
                    <div class="ml-auto d-flex align-center">
                      <div>
                        5,64%
                      </div>
                      <v-icon size="12" class="ml-1" color="#2ECC71">mdi-arrow-up-thick</v-icon>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="3">
            <v-skeleton-loader
              class="ma-auto"
              :loading="loading"
              transition="scale-transition"
              :tile="true"
              type="image">
              <v-card
                class="box-shadow border-radius pa-1"
                style="border-top: 5px solid #3498DB">
                <v-card-text>
                  <div style="font-weight: normal; font-size: 14px; color: #505050; line-height: 16px;">
                    Total Publisher
                  </div>
                  <div style="font-weight: 900; font-size: 24px; color: #505050; line-height: 28px; margin-top: 5px;">
                    {{ dashboard.count_publisher }}
                  </div>
                  <div style="font-size: 12px; line-height: 14px; color: #505050; mix-blend-mode: normal; opacity: 0.5;" class="mt-3">
                    vs Tahun Lalu
                  </div>
                </v-card-text>
              </v-card>
            </v-skeleton-loader>
          </v-col>
        </v-row>
      </div> -->

      <v-row v-if="!loading">
        <v-col cols="12" md="3">
          <v-skeleton-loader
            class="ma-auto"
            :loading="process.pie"
            transition="scale-transition"
            :tile="true"
            type="card">
            <v-card class="box-shadow border-radius pa-2">
              <v-card-text class="subtitle-2 text--darken-3">
                Total Kelas
                <p
                  class="text-second d-flex align-center"
                >
                  <span class="text-second mt-5 text-h4">
                    {{ dashboard.count_course }}
                  </span>
                  <v-spacer />
                  <span>
                    <v-icon style="font-size: 70px" color="#d0d0d0"
                      >mdi-book-variant</v-icon
                    >
                  </span>
                </p>
              </v-card-text>
            </v-card>
          </v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="3">
          <v-skeleton-loader
            class="ma-auto"
            :loading="process.pie"
            transition="scale-transition"
            :tile="true"
            type="card">
            <v-card class="box-shadow border-radius pa-2">
              <v-card-text class="subtitle-2 text--darken-3">
                Total Kelas Terjual
                <p
                  class="text-second d-flex align-center"
                >
                  <span class="text-second mt-5 text-h4">
                    {{ dashboard.count_transaction_course_paid }} 
                  </span>
                  <v-spacer />
                  <span>
                    <v-icon style="font-size: 70px" color="#d0d0d0"
                      >mdi-package-variant</v-icon
                    >
                  </span>
                </p>
              </v-card-text>
            </v-card>
          </v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="3">
          <v-skeleton-loader
            class="ma-auto"
            :loading="process.pie"
            transition="scale-transition"
            :tile="true"
            type="card">
            <v-card class="box-shadow border-radius pa-2">
              <v-card-text class="subtitle-2 text--darken-3">
                Total Organisasi
                <p
                  class="text-second d-flex align-center"
                >
                  <span class="text-second mt-5 text-h4">
                    {{ dashboard.count_organization }}
                  </span>
                  <v-spacer />
                  <span>
                    <v-icon style="font-size: 70px" color="#d0d0d0"
                      >mdi-office-building</v-icon
                    >
                  </span>
                </p>
              </v-card-text>
            </v-card>
          </v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="3">
          <v-skeleton-loader
            class="ma-auto"
            :loading="process.pie"
            transition="scale-transition"
            :tile="true"
            type="card">
            <v-card class="box-shadow border-radius pa-2">
              <v-card-text class="subtitle-2 text--darken-3">
                Total Publisher
                <p
                  class="text-second d-flex align-center"
                >
                  <span class="text-second mt-5 text-h4">
                    {{ dashboard.count_publisher }}
                  </span>
                  <v-spacer />
                  <span>
                    <v-icon style="font-size: 70px" color="#d0d0d0"
                      >mdi-account-multiple-outline</v-icon
                    >
                  </span>
                </p>
              </v-card-text>
            </v-card>
          </v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="3">
          <v-skeleton-loader
            class="ma-auto"
            :loading="loading"
            transition="scale-transition"
            :tile="true"
            type="image">
            <v-card
              class="box-shadow border-radius pa-1"
              style="border-top: 5px solid #3498DB">
              <v-card-text>
                <div style="font-weight: normal; font-size: 14px; color: #505050; line-height: 16px;">
                  Total Transaksi
                </div>
                <div style="font-weight: 900; font-size: 24px; color: #505050; line-height: 28px; margin-top: 5px;">
                  {{ dashboard.count_transaction }}
                </div>
                <div style="font-size: 18px; line-height: 14px; color: #3498DB; mix-blend-mode: normal; opacity: 1;" class="mt-3 font-weight-bold">
                  <div style="font-size: 12px; color: #505050; margin-bottom: 8px; font-weight: normal;">
                    Nilai
                  </div>
                  {{ dashboard.sum_transaction_total_paid_nett === null ? 0 : dashboard.sum_transaction_total_paid_nett | price }}
                </div>
              </v-card-text>
            </v-card>
          </v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="3">
          <v-skeleton-loader
            class="ma-auto"
            :loading="loading"
            transition="scale-transition"
            :tile="true"
            type="image">
            <v-card
              class="box-shadow border-radius pa-1"
              style="border-top: 5px solid #F1C40F">
              <v-card-text>
                <div style="font-weight: normal; font-size: 14px; color: #505050; line-height: 16px;">
                  Menunggu Komfirmasi
                </div>
                <div style="font-weight: 900; font-size: 24px; color: #505050; line-height: 28px; margin-top: 5px;">
                  {{ dashboard.count_transaction_waiting }}
                </div>
                <div style="font-size: 18px; line-height: 14px; color: #F1C40F; mix-blend-mode: normal; opacity: 1;" class="mt-3 font-weight-bold">
                  <div style="font-size: 12px; color: #505050; margin-bottom: 8px; font-weight: normal;">
                    Nilai
                  </div>
                  {{ dashboard.sum_transaction_total_paid_nett_waiting === null ? 0 : dashboard.sum_transaction_total_paid_nett_waiting | price }}
                </div>
              </v-card-text>
            </v-card>
          </v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="3">
          <v-skeleton-loader
            class="ma-auto"
            :loading="loading"
            transition="scale-transition"
            :tile="true"
            type="image">
            <v-card
              class="box-shadow border-radius pa-1"
              style="border-top: 5px solid #1ABC9C">
              <v-card-text>
                <div style="font-weight: normal; font-size: 14px; color: #505050; line-height: 16px;">
                  Selesai
                </div>
                <div style="font-weight: 900; font-size: 24px; color: #505050; line-height: 28px; margin-top: 5px;">
                  {{ dashboard.count_transaction_paid }}
                </div>
                <div style="font-size: 18px; line-height: 14px; color: #1ABC9C; mix-blend-mode: normal; opacity: 1;" class="mt-3 font-weight-bold">
                  <div style="font-size: 12px; color: #505050; margin-bottom: 8px; font-weight: normal;">
                    Nilai
                  </div>
                  {{ dashboard.sum_transaction_total_paid_nett_paid === null ? 0 : dashboard.sum_transaction_total_paid_nett_paid | price }}
                </div>
              </v-card-text>
            </v-card>
          </v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="3">
          <v-skeleton-loader
            class="ma-auto"
            :loading="loading"
            transition="scale-transition"
            :tile="true"
            type="image">
            <v-card
              class="box-shadow border-radius pa-1"
              style="border-top: 5px solid #E67E22">
              <v-card-text>
                <div style="font-weight: normal; font-size: 14px; color: #505050; line-height: 16px;">
                  Dibatalkan
                </div>
                <div style="font-weight: 900; font-size: 24px; color: #505050; line-height: 28px; margin-top: 5px;">
                  {{ parseInt(dashboard.count_transaction_paid) + parseInt(dashboard.count_transaction_cancelled) }}
                </div>
                <div style="font-size: 18px; line-height: 14px; color: #E67E22; mix-blend-mode: normal; opacity: 1;" class="mt-3 font-weight-bold">
                  <div style="font-size: 12px; color: #505050; margin-bottom: 8px; font-weight: normal;">
                    Nilai
                  </div>
                  {{ (parseInt(dashboard.sum_transaction_total_paid_nett_rejected) + parseInt(dashboard.sum_transaction_total_paid_nett_cancelled)) | price }}
                </div>
              </v-card-text>
            </v-card>
          </v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="12">
          <div v-if="loading">
            <v-skeleton-loader
              class="ma-auto"
              :loading="loading"
              transition="scale-transition"
              :tile="true"
              type="image">
            </v-skeleton-loader>
          </div>
          <v-card
            class="box-shadow border-radius pa-1"
            v-if="!loading">
            <div>
              <bar-chart
                id="bar"
                :data="xkey"
                :xkey="graphic.bar.xkey"
                :bar-colors="barColor"
                :ykeys="ykeys"
                :yLogScale="false"
                :resize="true"
                :labels="ykeys_label"
                xLabelAngle="60"
                :grid="true"
                :pointSize="4"
                :lineWidth="2"
                yLabels="5min"
                :parseTime="false"
                :padding="80"
                :xLabelMargin="50"
                >
              </bar-chart>
            </div>
          </v-card>
        </v-col>
        <!-- <v-col cols="12" md="3">
          <v-skeleton-loader
            class="ma-auto"
            :loading="process.pie"
            transition="scale-transition"
            :tile="true"
            type="card">
            <v-card class="box-shadow border-radius pa-2">
              <v-card-text class="subtitle-2 text--darken-3">
                Total Transaksi
                <p
                  class="text-second d-flex align-center"
                >
                  <span class="text-second mt-5 text-h4">
                    {{ dashboard.count_transaction }}
                    <div class="caption mt-2">
                      <div>
                        Nilai
                      </div>
                      {{ dashboard.sum_transaction_total_paid_nett === null ? 0 : dashboard.sum_transaction_total_paid_nett | price }}
                    </div>
                  </span>
                  <v-spacer />
                  <span>
                    <v-icon style="font-size: 70px" color="#d0d0d0"
                      >mdi-shopping-outline</v-icon
                    >
                  </span>
                </p>
              </v-card-text>
            </v-card>
          </v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="9">
          <v-skeleton-loader
            class="ma-auto"
            :loading="process.pie"
            transition="scale-transition"
            :tile="true"
            type="card">
            <v-card class="box-shadow border-radius pa-2">
              <v-card-text class="subtitle-2 text--darken-3">
                Nominal Transaksi
                <p
                  class="text-second d-flex align-center"
                >
                  <span class="text-second mt-5 text-h4">
                    {{ dashboard.sum_transaction_total_paid_nett === null ? 0 : dashboard.sum_transaction_total_paid_nett | price }}
                  </span>
                  <v-spacer />
                  <span>
                    <v-icon style="font-size: 70px" color="#d0d0d0"
                      >mdi-cash</v-icon
                    >
                  </span>
                </p>
              </v-card-text>
            </v-card>
          </v-skeleton-loader>
        </v-col> -->
      </v-row>
      <!-- <donut-chart 
        id="donut" 
        :data="donutData" 
        colors='[ "#FF6384", "#36A2EB", "#FFCE56" ]' 
        resize="true">
      </donut-chart> -->


    <v-dialog
      v-model="dialog.filter"
      v-if="dialog.filter"
      persistent
      max-width="350"
      scrollable>
      <v-card tile>
        <v-card-title
          class="subtitle-1 px-4 py-2 d-block"
          style="color: #4ab762"
        >
          Filter Data <br />
          <div class="caption grey--text text--darken-1">
            Isi form dibawah ini untuk memfilter
          </div>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-4 pt-2 px-4">
          <v-form
            ref="form-report"
            v-model="valid"
            lazy-validation
            @keyup.native.enter="form_filter.sort_filter = 'date'; toFilter()">
            <div class="subtitle-2 mb-6">
              Tanggal Transaksi
            </div>
            <v-row>
              <v-col :cols="12" class="py-0">
                <v-menu
                  v-model="filter_start_date"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="form_filter.tanggal_mulai"
                      :label="'Mulai Dari'"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-on:change="form_filter.tanggal_selesai = '';"
                      dense
                      solo
                      autocomplete="off"
                      color="#4ab762"
                      type="text"
                      placeholder="Tulis disini"
                      class="rounded-lg mb-3"
                      :disabled="loading"
                      v-bind="attrs"
                      v-on="on"
                      :error-messages="validation.start_date"
                      @keyup="validation.start_date = ''"
                      :rules="[
                        (v) => !!v || `Tanggal mulai tidak boleh kosong`,]"
                    >
                      <template v-slot:message="{ message }">
                        <v-tooltip top max-width="250" color="#e74c3c">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              size="22"
                              class="mr-2 ml-n2"
                              color="#e74c3c"
                              v-bind="attrs"
                              v-on="on"
                              >mdi-alert-circle-outline</v-icon
                            >
                          </template>
                          <span>{{ message }}</span>
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form_filter.tanggal_mulai"
                    color="#4ab762"
                    :max="reminder"
                    @input="filter_start_date = false; changeDate()"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-menu
                  v-model="filter_end_date"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="form_filter.tanggal_selesai"
                      :label="'Kurang Dari'"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      :disabled="loading || form_filter.tanggal_mulai === ''"
                      dense
                      solo
                      autocomplete="off"
                      color="#4ab762"
                      type="text"
                      placeholder="Tulis disini"
                      class="rounded-lg mb-3"
                      v-bind="attrs"
                      v-on="on"
                      :error-messages="validation.end_date"
                      @keyup="validation.end_date = ''"
                      :rules="[
                        (v) => !!v || `Tanggal akhir tidak boleh kosong`
                      ]"
                    >
                      <template v-slot:message="{ message }">
                        <v-tooltip top max-width="250" color="#e74c3c">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              size="22"
                              class="mr-2 ml-n2"
                              color="#e74c3c"
                              v-bind="attrs"
                              v-on="on"
                              >mdi-alert-circle-outline</v-icon
                            >
                          </template>
                          <span>{{ message }}</span>
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form_filter.tanggal_selesai"
                    color="#4ab762"
                    :min="form_filter.tanggal_mulai"
                    :max="max_tanggal"
                    @input="filter_end_date = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-space-between px-4">
          <v-btn
            rounded
            depressed
            class="text-none white--text text--lighten-3 flex-grow-1"
            color="#4ab762"
            @click="form_filter.sort_filter = 'date'; toFilter()"
          >
            <v-icon left>mdi-check-circle-outline</v-icon
            >Simpan Filter
          </v-btn>
          <v-btn
            rounded
            depressed
            outlined
            class="text-none"
            color="#e74c3c"
            width="130"
            @click="dialog.filter = false"
            >Batal</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog
      v-model="dialog.sort_filter"
      v-if="dialog.sort_filter"
      persistent
      max-width="250"
      scrollable>
      <v-card tile>
        <v-card-title
          class="subtitle-1 px-4 py-2 d-block"
          style="color: #4ab762"
        >
          Filter Data <br />
          <div class="caption grey--text text--darken-1">
            Pilih tombol dibawah ini untuk memfilter
          </div>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-5 pt-4 px-4">
          <v-row>
            <v-col cols="6" v-for="(i, k) in sort_filter" :key="k" class="text-center">
              <v-btn
                elevation="0"
                :outlined="filter.sort_filter === i.name ? true : false"
                width="80"
                height="80"
                class="text-capitalize"
                style="white-space: normal !important; letter-spacing: normal; display: unset;"
                @click="form_filter.sort_filter = i.name; toFilter('tombol');"
                :color="filter.sort_filter === i.name ? '#4ab762' : 'rgb(247, 247, 247)'">
                <div>
                  <div>
                    <v-icon>{{ i.icon }}</v-icon>
                  </div>
                  {{ i.name }}
                </div>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-space-between px-4">
          <v-btn
            rounded
            depressed
            outlined
            class="text-none"
            color="#e74c3c"
            block
            @click="dialog.sort_filter = false"
            >Batal</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Raphael from 'raphael/raphael'
global.Raphael = Raphael
import { BarChart } from 'vue-morris'
export default {
  name: "Dashboard",

  data: () => ({
    dialog: {
      offline: false,
      filter: false,
      sort_filter: false
    },
    loading: true,
    sort_filter: [
      {
        name: "Minggu Ini",
        icon: "mdi-calendar-week"
      },
      {
        name: "Bulan Ini",
        icon: "mdi-calendar-week"
      },
      {
        name: "Tahun Ini",
        icon: "mdi-calendar-week"
      },
      {
        name: "Tahun Kemarin",
        icon: "mdi-calendar-week"
      }
    ],
    xkey: [],
    filter_start_date: false,
    filter_end_date: false,
    filter: {
      tanggal_mulai: "",
      tanggal_selesai: "",
      sort_filter: "Tahun Ini"
    },
    form_filter: {
      tanggal_mulai: "",
      tanggal_selesai: "",
      sort_filter: "Tahun Ini"
    },
    count:{
      transaction: {},
      class: {}
    },
    donutData: [
      { label: 'Red', value: 300 },
      { label: 'Blue', value: 50 },
      { label: 'Yellow', value: 100 }
    ],
    barData: [
      { y: '1 Januari 2014', a: 50, b: 90, c: 50, d: 90},
      { y: '2 Januari 2014', a: 50, b: 90, c: 50, d: 90},
      { y: '3 Januari 2014', a: 50, b: 90, c: 50, d: 90},
      { y: '4 Januari 2014', a: 50, b: 90, c: 50, d: 90},
      { y: '5 Januari 2014', a: 50, b: 90, c: 50, d: 90},
      { y: '6 Januari 2014', a: 50, b: 90, c: 50, d: 90},
      { y: '7 Januari 2014', a: 50, b: 90, c: 50, d: 90},
      { y: '8 Januari 2014', a: 50, b: 90, c: 50, d: 90},
      { y: '9 Januari 2014', a: 50, b: 90, c: 50, d: 90},
      { y: '10 Januari 2014', a: 50, b: 90, c: 50, d: 90},
      { y: '11 Januari 2014', a: 50, b: 90, c: 50, d: 90},
      { y: '12 Januari 2014', a: 50, b: 90, c: 50, d: 90},
      { y: '13 Januari 2014', a: 50, b: 90, c: 50, d: 90},
      { y: '14 Januari 2014', a: 50, b: 90, c: 50, d: 90},
      { y: '15 Januari 2014', a: 50, b: 90, c: 50, d: 90},
      { y: '16 Januari 2014', a: 50, b: 90, c: 50, d: 90},
      { y: '17 Januari 2014', a: 50, b: 90, c: 50, d: 90},
      { y: '18 Januari 2014', a: 50, b: 90, c: 50, d: 90},
      { y: '19 Januari 2014', a: 50, b: 90, c: 50, d: 90},
      { y: '20 Januari 2014', a: 50, b: 90, c: 50, d: 90},
      { y: '21 Januari 2014', a: 50, b: 90, c: 50, d: 90},
      { y: '22 Januari 2014', a: 50, b: 90, c: 50, d: 90},
      { y: '23 Januari 2014', a: 50, b: 90, c: 50, d: 90},
      { y: '24 Januari 2014', a: 50, b: 90, c: 50, d: 90},
      { y: '25 Januari 2014', a: 50, b: 90, c: 50, d: 90},
      { y: '26 Januari 2014', a: 50, b: 90, c: 50, d: 90},
      { y: '27 Januari 2014', a: 50, b: 90, c: 50, d: 90},
      { y: '28 Januari 2014', a: 50, b: 90, c: 50, d: 90},
      { y: '29 Januari 2014', a: 50, b: 90, c: 50, d: 90},
      { y: '30 Januari 2014', a: 50, b: 90, c: 50, d: 90},
      { y: '31 Januari 2014', a: 50, b: 90, c: 50, d: 90},

    ],
    barColor: ['#3498DB', '#F1C40F', '#1ABC9C', '#E67E22'],
    ykeys_label: ['Total Transaksi', 'Menunggu Komfirmasi', 'Selesai', 'Dibatalkan'],
    ykeys: ['count_transaction', 'count_transaction_waiting', 'count_transaction_paid', 'count_transaction_rejected'],
    list:[],
    graphic: [],
    bar: {},
    summary: {},
    income: 0,
    dashboard: {},
    chart1: undefined,
    chart2: undefined,
    max_tanggal: "",
    validation: {
      start_date: "",
      end_date: ""
    },
    process: {
      run: false,
      summary: false,
      chart: false,
      pie: false,
    },
    breadcrumbs: [
      {
        text: "Dashboard",
        disabled: false,
        href: "/",
      },
    ],
  }),
  components: {
    BarChart
  },

  computed: {
    reminder () {
      let now = new Date(),
          next = new Date(now)
      next.setDate(now.getDate())
      return next.toISOString().substr(0, 10)
    },
  },

  mounted() {
    this.fetchData()
  },

  methods: {
    async fetchData() {
      let firstday = '';
      let lastday = '';
      if (this.filter.sort_filter === 'Minggu Ini') {
        let curr = new Date; // get current date
        let first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
        let last = first + 6; // last day is the first day + 6

        firstday = new Date(curr.setDate(first)).toISOString().slice(0, 10);
        lastday = new Date(curr.setDate(last)).toISOString().slice(0, 10);
      }
      this.loading = true;

      let params = {
        filter_type: this.filter.sort_filter === 'Tahun Ini' || this.filter.sort_filter === 'Tahun Kemarin' ? 'year' : this.filter.sort_filter === 'Bulan Ini' ? 'month' : 'date_range',
        filter_value: this.filter.sort_filter === 'Tahun Ini' ? new Date().getFullYear() : this.filter.sort_filter === 'Tahun Kemarin' ? new Date().getFullYear() - 1 : this.filter.sort_filter === 'Bulan Ini' ? `${new Date().getFullYear()}-${new Date().getMonth()}` : this.filter.sort_filter === 'Minggu Ini' ? JSON.stringify([firstday, lastday]) : JSON.stringify([this.filter.tanggal_mulai, this.filter.tanggal_selesai])
      }

      let response = await this.$get("admin/dashboard", params );

      // this.loading = false;

      if (response.status == 200) {
        this.dashboard = response.results.data;
        this.fetchDataGrafik()
      } else {
        this.$store.state.snackbar = {
          show: true,
          text: "Gagal memuat data.",
          color: "#f39c12",
        };
      }
    },

    async fetchDataGrafik() {
      let firstday = '';
      let lastday = '';
      if (this.filter.sort_filter === 'Minggu Ini') {
        let curr = new Date; // get current date
        let first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
        let last = first + 6; // last day is the first day + 6

        firstday = new Date(curr.setDate(first)).toISOString().slice(0, 10);
        lastday = new Date(curr.setDate(last)).toISOString().slice(0, 10);
      }
      this.loading = true;


      let params = {
        filter_type: this.filter.sort_filter === 'Tahun Ini' || this.filter.sort_filter === 'Tahun Kemarin' ? 'year' : this.filter.sort_filter === 'Bulan Ini' ? 'month' : 'date_range',
        filter_value: this.filter.sort_filter === 'Tahun Ini' ? new Date().getFullYear() : this.filter.sort_filter === 'Tahun Kemarin' ? new Date().getFullYear() - 1 : this.filter.sort_filter === 'Bulan Ini' ? `${new Date().getFullYear()}-${new Date().getMonth()}` : this.filter.sort_filter === 'Minggu Ini' ? JSON.stringify([firstday, lastday]) : JSON.stringify([this.filter.tanggal_mulai, this.filter.tanggal_selesai])
      }

      let response = await this.$get("admin/dashboard/graphic", params );

      this.loading = false;

      if (response.status == 200) {
        let array_graphic = []
        this.graphic = response.results.data;
        for (let index = 0; index < response.results.data.bar.data.length; index++) {
          array_graphic.push({
            date: response.results.data.bar.data[index].date,
            count_transaction: response.results.data.bar.data[index].count_transaction,
            count_transaction_waiting: response.results.data.bar.data[index].count_transaction_waiting,
            count_transaction_paid: response.results.data.bar.data[index].count_transaction_paid,
            count_transaction_rejected: parseInt(response.results.data.bar.data[index].count_transaction_rejected) + parseInt(response.results.data.bar.data[index].count_transaction_cancelled)
          })
        }
        this.xkey = array_graphic
      } else {
        this.$store.state.snackbar = {
          show: true,
          text: "Gagal memuat data.",
          color: "#f39c12",
        };
      }
    },

    changeDate() {
      let now = new Date(this.form_filter.tanggal_mulai),
          next = new Date(now)
      next.setDate(now.getDate()+90)
      this.max_tanggal = next.toISOString().substr(0, 10)
    },

    validationReset() {
      Object.assign(this.validation, {
        start_date: "",
        end_date: ""
      });
    },

    toFilter(item) {
      let isValid = item === undefined ? this.$refs["form-report"].validate() : true

      if (isValid) {
        this.filter = {
          tanggal_mulai: this.form_filter.tanggal_mulai,
          tanggal_selesai: this.form_filter.tanggal_selesai,
          sort_filter: this.form_filter.sort_filter
        },
        this.fetchData()
        this.dialog.filter = false
        this.dialog.sort_filter = false
        this.validationReset();
      }
    },

    inputFilter(item) {
      this.form_filter = {
        tanggal_mulai: this.filter.tanggal_mulai,
        tanggal_selesai: this.filter.tanggal_selesai,
        sort_filter: this.filter.sort_filter
      },
      this.dialog.filter = true
    },
  },
};
</script>

<style>
</style>